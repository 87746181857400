import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { RichText } from "prismic-reactjs";
import Seo from "../components/Seo";

// material imports
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  Box,
  Button,
  IconButton,
  Container
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";

const Article = ({ data }) => {
  const articleUrl = `https://levercode.com/news/${data.prismicNews.uid}`;
  return (
    <Layout>
      <Seo
        title={data.prismicNews.data.title.text}
        description={data.prismicNews.data.seo_description.text}
      />
      <Container maxWidth="xl">
        <Box
          className="news-page single-post"
          sx={{
            mt: -2,
            mb: 14,
            mx: "auto",
          }}
        >
          <Grid
            container
            spacing={4}
            sx={{ maxWidth: "1600px", mt: -2, mb: { xs: -8, sm: -8, md: 2 } }}
            noGutters
          >
            <Grid item xs={12}>
              <Box>
                <Link to="/news">
                  <Button
                    startIcon={<ArrowBackIcon />}
                    variant="text"
                    sx={{
                      textTransform: "none",
                      mb: 2,
                      ml: {
                        xs: 2,
                        sm: 2,
                        md: 0,
                      },
                    }}
                  >
                    Back to all news
                  </Button>
                </Link>
                <Card elevation={20}>
                  <CardMedia
                    component="img"
                    height="600"
                    image={data.prismicNews.data.image.url}
                    alt={data.prismicNews.data.image.alt}
                  />
                  <CardContent
                    sx={{
                      px: {
                        sm: 0,
                        md: 35,
                      },
                      py: {
                        xs: 4,
                        sm: 4,
                        md: 8,
                      },
                    }}
                  >
                    <Typography sx={{ fontWeight: 800, fontSize: "14px" }}>
                      {data.prismicNews.data.publish_date}
                    </Typography>
                    <Typography
                      component="h1"
                      sx={{
                        color: "#000dff",
                        fontSize: "2.5rem",
                        lineHeight: "1.25",
                        fontWeight: 500,
                        mb: 2,
                        mt: 2,
                        px: 0,
                      }}
                    >
                      {data.prismicNews.data.title.text}
                    </Typography>
                    <Box className="richtext-wrapper">
                      {RichText.render(data.prismicNews.data.content_field.raw)}
                    </Box>
                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", mt: 4 }}
                    >
                      Share this article:
                    </Typography>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      sx={{ mb: { xs: 2, sm: 2, md: 4 } }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => {
                          window.open(
                            `https://www.facebook.com/sharer.php?u=${articleUrl}`,
                            data.prismicNews.data.title.text,
                            "width=500,height=500,top=300px,left=300px"
                          );
                          return false;
                        }}
                      >
                        <FacebookIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          window.open(
                            `https://twitter.com/share?url=${articleUrl}`,
                            data.prismicNews.data.title.text,
                            "width=500,height=500,top=300px,left=300px"
                          );
                          return false;
                        }}
                      >
                        <TwitterIcon />
                      </IconButton>
                      <a
                        href={`mailto:enteryour@addresshere.com?subject=${encodeURIComponent(
                          data.prismicNews.data.title.text
                        )}&body=${"Hi! Check out this article I just found: "}${articleUrl}`}
                      >
                        <IconButton color="primary">
                          <EmailIcon />
                        </IconButton>
                      </a>
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Layout>
  );
};

export default Article;

export const articleQuery = graphql`
  query Article($slug: String) {
    prismicNews(uid: { eq: $slug }) {
      uid
      data {
        title {
          text
        }
        publish_date(formatString: "D MMMM YYYY")
        seo_title {
          text
        }
        seo_description {
          text
        }
        content_field {
          raw
        }
        image {
          alt
          url
        }
      }
    }
  }
`;
